import { format } from "date-fns";

export const numberFormatter = (number) => {
  const numberFormatterInstance = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `$${numberFormatterInstance.format(number)}`;
};

export const dateFormatter = (date) => {
  date = new Date(date);
  return format(date, "MM/dd/yyyy");
};

export const capitalize = (string) => {
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
