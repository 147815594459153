{
  "common": {
    "send": "Send",
    "card": "Card",
    "service": "Service",
    "plan": "plan",
    "next": "Next"
  },
  "setting": {
    "text": "GigNet App update available - Click to update"
  },
  "plan": {
    "estatus": "Status",
    "next_invoice": "Next invoice",
    "type": "Type",
    "cut_off": "Cutoff date",
    "subscription": "Subscription",
    "bank_reference": "Bank reference",
    "address": "Address"
  },
  "titles": {
    "Home": "Home",
    "Profile": "Profile",
    "CardsIndex": "My Cards",
    "CardsNew": "New Card",
    "SupportIndex": "Support tickets",
    "SupportAdd": "New ticket",
    "ChangePass": "Change password",
    "PlanDetails": "",
    "InvoicesView": "",
    "InvoicesPay": "Pay invoice",
    "QuickPaymentSuccess": "",
    "Login": "",
    "QuickPayment": "",
    "LeadForm": "",
    "ResetPass": "",
    "Map": "",
    "AddressForm": "",
    "NewUser": "",
    "QuickPaymentInvoices": "Quick payment"
  },
  "ticket": {
    "close_date": "Close date",
    "create_date": "Creation date"
  },
  "menu": {
    "home": "Home",
    "cards": "Cards",
    "support": "Support"
  },
  "userOptions": {
    "profile": "Profile",
    "changePass": "Change password",
    "forgotPass": "Forgot my password",
    "logout": "Logout",
    "newUser": "Are you a GigNet customer and you don't have your username yet? Click here"
  },
  "password_reset": {
    "button": "Request new password",
    "check_mail": "Check your inbox for the next steps or check your spam folder",
    "valid_email": "Valid email",
    "success": "Success!",
    "password_changed": "Password changed"
  },
  "map": {
    "select_address": "Coverage areas are displayed in blue.",
    "selected_address": "Selected address"
  },
  "lead": {
    "form_name": "Contact us",
    "name": "Business name",
    "contact_name": "Contact name",
    "email": "Email",
    "mobile": "Phone number",
    "street": "Street",
    "type_service": "Type of service",
    "location_of_service": "Location of service",
    "success_title": "Success",
    "success_subtitle": "Customer service will contact you"
  },
  "login": {
    "title": "Already a GigNet customer?",
    "username": "Username",
    "password": "Password",
    "send": "Login",
    "quick": "Quick payment",
    "client": "I want to be a customer",
    "call": "Support",
    "notice": "By using this platform, you agree to our <a target='_BLANK' href='https://gignet.mx/es/politica-privacidad'> privacy policies </a>",
    "error_title": "Error",
    "error_subtitle": "Incorrect email or password"
  },
  "register": {
    "error_title": "Error",
    "error_subtitle": "Service number not found"
  },
  "changePass": {
    "current": "Current",
    "new": "New",
    "confirm": "Confirm New",
    "success_title": "Success",
    "success_subtitle": "Your password has been successfully changed"
  },
  "homepage": {
    "my_services": "My services",
    "hello": "Hello {name}!"
  },
  "credit_card": {
    "expires": "Expires",
    "number": "Number",
    "add_error": "Unable to add card",
    "exp_month": "Month",
    "exp_year": "Year",
    "cvc": "CVC",
    "new": "New card",
    "select": "Select",
    "write": "Write",
    "checkbox": "I authorize to carry out billing activities and process payments through the forms and mechanisms enabled by GIGNET, related to the use of goods and/or services provided by third parties."
  },
  "support": {
    "type": "Type of ticket",
    "types": {
      "service_failures": "Service failures",
      "payments": "Payments & invoices",
      "plans": "Plans"
    },
    "description": "Description",
    "send": "Send"
  },
  "services": {
    "service": "Service #"
  },
  "payment": {
    "success_title": "Payment successful!",
    "success_description": "The invoice has been sent to the assigned email",
    "success_button": "Ok",
    "subscription": {
      "title": "You have a subscription!",
      "subtitle": "Payment will be made automatically"
    },
    "info": "Thank you very much for your payment, this will be reflected in a period of no more than one hour."
  },
  "profile": {
    "username": "Username",
    "name": "Name",
    "email": "email",
    "street": "Street",
    "save": "Save",
    "business_name": "Business name",
    "phone": "Phone",
    "city": "City",
    "street_number": "Street Ext",
    "street_number2": "Street Int",
    "colony": "Colony",
    "locality": "Locality",
    "reference": "Reference"
  },
  "quickpayment": {
    "notfound_title": "No results found",
    "notfound_subtitle": "No results found for the requested service.",
    "continue": "Search",
    "clean": "Clean results",
    "cc_label": "Enter your credit card details to continue.",
    "service_number": "Service number",
    "pay": "Pay",
    "subscribe": "Subscribe",
    "error_title": "Error",
    "error_subtitle": "There was an error processing the payment",
    "check": "I have verified that my information is correct.",
    "caption": "Check that the information matches your contract number, to avoid erroneous payment for your service. For your greater security, you are always invited to enter the portal with your username and password to make your payment(s).",
    "info": "We remind you that payments can be reflected in a period of no more than one hour."
  },
  "invoices": {
    "invoice": "Invoice",
    "id": "ID",
    "name": "Name",
    "partner_id": "Client",
    "plan": "Plan",
    "reference": "Bank reference",
    "next_invoice_date": "Next invoice date",
    "subscription": "Subscription",
    "summary_amount": "Total outstanding amount",
    "summary_payment": "Payment amount",
    "summary_pending": "Pending Amount",
    "servicio_id": "Service",
    "invoice_date": "Date",
    "invoice_payment_term_id": "Term",
    "amount_total": "Total",
    "amount_residual": "Residual",
    "state": "State",
    "paid": "Paid", 
    "pay": "Pay",
    "pending_helper": "You have previous unpaid invoices.",
    "subscription_helper": "The service has a subscription, the charge is automatic to your card",
    "confirm": "I hereby authorize GigNet, S.A. de C.V, so that I can charge my credit or debit card, the amount for the services that it provides me monthly.",
    "cancelled": "Cancelled",
    "not_payed": "Not paid",
    "filter_by": "Filter by",
    "pending_payment": "Processing",
    "message_empty": "There are no invoices pending payment"
  },
  "contract": {
    "contact_form": {
      "form_name": "Contact info",
      "id_vendedor": "Seller ID (to be filled by sales if applicable)",
      "name": "Name",
      "email": "Email",
      "phone": "Phone",
      "mobile": "Mobile Phone",
      "identificacion": "ID",
      "comprobante": "Proof of address",
      "business_name": "Business name",
      "identification": "Identification",
      "invoice_check": "I require fiscal invoice",
      "check": "I have verified that my information is correct and I accept the <a target='_blank' href='https://gignet.mx/es/politica-privacidad/'>Privacy policy<a>."
    },
    "address_form": {
      "form_name": "Address of the installation",
      "coverage_zone": "Coverage Zone",
      "type_locality": "Settlement type",
      "street": "Street",
      "ext": "#Ext",
      "int": "#Int",
      "city": "City",
      "suburb": "Colony",
      "zip_code": "Zip code",
      "settlement": "Settlement",
      "aditional_info": "Additional info",
      "referal": "How did you hear about us?",
      "channel_other": "Please specify"
    }
  },
  "wizard_contract": {
    "steps": {
      "address": "Service Address",
      "contact": "Contact",
      "plans": "Plans",
      "customer": "Customer",
      "contract": "Contract",
      "end": "End"
    },
    "address": {
      "title": "Check availability for new residential services.",
      "city": "City",
      "zone": "Zone",
      "township": "Address",
      "building": "Building",
      "btn_send": "Next",
      "btn_back": "Back",
      "box_header_text": "Don’t see your community?",
      "box_body_text": "We're always expanding our network. If you don't see your community listed above, contact us using the form below to let us know you're interested in GigNet Home services.",
      "btn_fillform": "Fill Form"
    },
    "plans": {
      "title": "Select your package",
      "prefix": "Up to",
      "period": "Monthly",
      "install": "Installation",
      "label_promotion": "Up to"
    },
    "contact":{
      "title": "Contact Information",
      "name": "name",
      "email": "email",
      "phone": "phone",
      "city": "City",
      "colony": "Address",
      "building": "Building or Residential Complex",
      "zip": "zip",
      "link_maps": "GPS Location",
      "comment": "Additional information",
      "info_gps": "If you are in the location of the service, click on the icon to generate your GPS location",
      "gps_steps": {
        "one": "On your phone, tablet, or computer, open the Google Maps app",
        "two": "Search for the place or locate it on the map and hold it down to put a pin.",
        "three": "At the top of the screen, select the site link.",
        "four": "Right-click it and select Copy.",
        "five": "Hit paste with the secondary button in the section where it asks you."
      },
      "btn_send": "Send"
    },
    "end":{
      "title": "Your request has been generated successfully, thank you for helping us to constantly expand. One of our customer service executives will contact you as soon as we have coverage in your area.",
      "subtitle": "For more information or follow-up, do not hesitate to contact us on our hotline. 9986900690.",
      "phone": "9986900690",
      "label": "The end"
    }
  },
  "signature": {
    "check": "I agree with the terms and conditions of contracting the service of GigNet S.A. de C.V.",
    "save": "Save",
    "clear": "Clear"
  }
}
