<template lang="pug">
//- pre | {{ services }}
.home
  h1.title {{ $t("homepage.my_services") }}
  .home__body
    app-plan(v-for="service in services" :plan="service")
</template>

<script>
import { ref, onMounted } from "vue";
import { axios } from "@/utils/axios";
import AppPlan from "@/components/AppPlan";

import { store } from "@/store";

export default {
  components: { AppPlan },
  setup() {
    const services = ref([]);
    const loading = ref(false);

    const getServices = async () => {
      const { data } = await axios.get("service/");

      return data.payload;
    };

    onMounted(async () => {
      try {
        store.APP_LOADING = true;
        services.value = await getServices();
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    });

    return { services, loading };
  },
};
</script>

<style lang="scss" scoped>
.card + .card {
  @apply m-0;
}
.title {
  @apply font-medium;
  @apply text-bluegray-500;
  @apply text-base;
  @apply mb-2;
  @apply inline-block;
}
.home {
  &__body {
    @apply grid;
    @apply grid-cols-1;
    @apply gap-4;
    @screen sm {
      @apply grid-cols-2;
    }
    @screen md {
      @apply grid-cols-2;
    }
    @screen xl {
      @apply grid-cols-3;
    }
  }
}
</style>
