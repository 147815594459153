<template lang="pug">
.login
  img(:src="require('@/assets/logo.png')")
  app-guest-menu(:options="guestMenuOptions") 
  AppCard
    .text-center
      span(class="label") {{$t('login.title')}}
    AppAlert.mb-4(:title="$t('login.error_title')" :subtitle="$t('login.error_subtitle')" v-if="hasError")
    form(@submit.prevent="login")
      AppFormInput(type="text" name="username" :label="$t('login.username')" :disabled="loading" required)
      AppFormInput.mb-2(type="password" name="password" :label="$t('login.password')" :disabled="loading" required)
      AppButton(:loading="loading" :disabled="loading" :label="$t('login.send')")
    .text-center
      router-link.login__secondary-option(:to="{ name: 'ResetPass' }") {{ $t("userOptions.forgotPass") }}
    .text-center
      router-link.login__secondary-option(:to="{ name: 'NewUser' }") {{ $t("userOptions.newUser") }}
  AppHelpText(v-html="$t('login.notice')")
</template>

<script>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { axios, setToken } from "@/utils/axios";
import AppFormInput from "@/components/Form/AppFormInput";
import AppButton from "@/components/AppButton";
import AppCard from "@/components/AppCard";
import AppAlert from "@/components/AppAlert";
import AppGuestMenu from "@/components/AppGuestMenu";
import AppHelpText from "@/components/AppHelpText";

import { store } from "@/store";
import { encrypt } from "@/utils/crypto";

export default {
  components: {
    AppFormInput,
    AppButton,
    AppCard,
    AppAlert,
    AppGuestMenu,
    AppHelpText,
  },
  setup() {
    const loading = ref(false);
    const hasError = ref(false);
    const router = useRouter();
    const { t } = useI18n();
    //
    const guestMenuOptions = [
      {
        icon: "uil uil-credit-card-search",
        label: t("login.quick"),
        route: "QuickPayment",
      },
      {
        icon: "uil uil-store",
        label: t("login.client"),
        route: "LeadForm",
      },
      {
        icon: "uil uil-phone",
        label: t("login.call"),
        url: "9986900610",
      },
    ];

    onMounted(() => {
      store.APP_LOADING = false;
    });

    const login = async (e) => {
      try {
        if (loading.value) {
          return;
        }

        hasError.value = false;
        loading.value = true;

        const formDataObject = {};
        const formData = new FormData();
        const body = new FormData(e.target);

        body.forEach((value, key) => (formDataObject[key] = value));

        formDataObject.password = encrypt(formDataObject.password);

        for (var key in formDataObject) {
          formData.append(key, formDataObject[key]);
        }

        const { data } = await axios.post("contact/signin", formData);

        setToken(data.payload);
        router.push({ name: "Home" });
      } catch (error) {
        hasError.value = true;
        console.log(error);
      } finally {
        loading.value = false;
        store.APP_LOADING = false;
      }
    };

    return { login, loading, guestMenuOptions, hasError };
  },
};
</script>

<style lang="scss" scoped>
.login {
  @apply min-h-full;
  @apply flex;
  @apply justify-evenly;
  @apply items-center;
  @apply flex-col;
  @apply mt-auto;
  @apply overflow-x-scroll;
  img {
    @apply mb-6;
    @apply w-2/3;
    @apply max-h-24;
    @apply w-auto;
    filter: drop-shadow(0, 2px 2px, rgba(0, 0, 0, 0.5));
  }
  &__secondary-option {
    @apply text-bluegray-400;
    @apply text-sm;
    @apply mt-4;
    @apply inline-block;
  }
}
.label {
  @apply text-bluegray-400;
  @apply text-sm;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
