<template lang="pug">
component(:is="layout")
  router-view(v-slot="{ Component, route }")
    transition(name="route" mode="out-in")
      .h-full
        //- AppUpdateAvailable
        AppTitle
        component(:is="Component")
        AppHelpText GigNet, S.A. de C.V. a través de su subsidiaria, SanAlto Redes Peninsular, S.A.P.I. de C.V., concesionaria del SERVICIO contratado.  
</template>

<script>
import { onMounted, computed, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";
import AppLoading from "@/components/AppLoading";
import AppTitle from "@/components/AppTitle";
import AppHelpText from "@/components/AppHelpText";
import AppUpdateAvailable from "@/components/AppUpdateAvailable";

import { axios, getToken } from "@/utils/axios";
import { store } from "@/store";

export default {
  components: {
    Blank: defineAsyncComponent(() => import("@/layouts/Blank.vue")),
    Default: defineAsyncComponent(() => import("@/layouts/Default.vue")),
    AppLoading,
    AppTitle,
    AppHelpText,
    AppUpdateAvailable,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      return route.meta.layout || "Default";
    });

    onMounted(async () => {
      const { access_token } = getToken();

      if (access_token) {
        const { data } = await axios.get("contact/");
        store.user = data.payload;
      }
    });

    return { layout };
  },
};
</script>
