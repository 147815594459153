import crypto from "crypto-js";

export function encrypt(str) {
  let password = "1024gignet1024";
  let salt = "odoo2021";

  let iterations = 128;

  let bytes = crypto.PBKDF2(password, salt, {
    keySize: 48,
    iterations: iterations,
  });

  let iv = crypto.enc.Hex.parse(bytes.toString().slice(0, 32));
  let key = crypto.enc.Hex.parse(bytes.toString().slice(32, 96));

  let ciphertext = crypto.AES.encrypt(str, key, { iv });

  return ciphertext.toString();
}
