<template>
  <select v-model="currentValue" :name="name" class="input">
    <option
      v-for="(option, index) in options"
      :value="option.value"
      :key="index"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import { watch, computed, ref } from "vue";
export default {
  props: {
    options: {
      type: Array,
    },
    name: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const currentValue = ref(props.value);
    const selected = ref(props.value);
    const options = computed(() => props.options);

    watch(options, () => {
      currentValue.value = selected.value;
    });

    watch(currentValue, (value) => {
      emit("input", value);
    });
    return { currentValue };
  },
};
</script>
