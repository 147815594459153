import axios from "axios";
import Cookies from "js-cookie";
import router from "@/router";

const getToken = () => {
  const access_token = Cookies.get("g_at");
  const refresh_token = Cookies.get("g_rt");

  return { access_token, refresh_token };
};

const setToken = ({ access_token, refresh_token }) => {
  Cookies.set("g_at", access_token);
  Cookies.set("g_rt", refresh_token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
};

const clearToken = () => {
  Cookies.remove("g_at");
  Cookies.remove("g_rt");
  axios.defaults.headers.common["Authorization"] = null;
};

const responseInterceptor = (error) => {
  if (error.response.status === 401) {
    clearToken();
    router.push({ name: "Login" });
  }

  return Promise.reject(error);
};

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.interceptors.response.use((response) => response, responseInterceptor);

export { axios, getToken, setToken, clearToken };
