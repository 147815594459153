import { reactive } from "vue";

export const store = reactive({
  APP_LOADING: false,
  APP_TITLE: "",
  APP_BACK_BUTTON: false,
  I118N_TITLE: false,
  user: {},
  invoicePaid: {},
  invoices_to_be_paid: [],
});
