import { createApp } from "vue";
import VueGtag from "vue-gtag-next";
// import * as Sentry from "@sentry/browser";
// import { Integrations } from "@sentry/tracing";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./assets/tailwind.css";
import "@iconscout/unicons/css/line.css";
import "@iconscout/unicons/css/solid.css";
import i18n from "./i18n";
import ClickOutside from "@/directives/ClickOutside";
import VueSignaturePad from "vue-signature-pad";

// Sentry.init({
//   environment: process.env.NODE_ENV,
//   dsn: "https://c08734bd1a5eb2b449f22111bdfa49fc@o683890.ingest.sentry.io/4505862960775168",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(VueSignaturePad);
app.use(ClickOutside);
app.use(VueGtag, {
  property: {
    id: "G-QV4ZKR35DT",
  },
});

// app.config.errorHandler = (err) => {
//   Sentry.captureException(err);
// };

router.isReady().then(() => {
  app.mount("#app");
});

// window.addEventListener("error", (event) => {
//   Sentry.captureException(event);
// });

// window.addEventListener("unhandledrejection", (event) => {
//   Sentry.captureException(event);
// });
