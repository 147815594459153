<template lang="pug">
AppCard(class="card-update" v-if="update_required")
    .row
        .col-4
            p(class="text-center" @click="updateApp") {{ $t('setting.text') }}
</template>
<script>
import { onMounted, ref } from "vue";
import AppButton from "./AppButton.vue";
import AppCard from "./AppCard.vue";
import { axios } from "@/utils/axios";
export default {
  components: { AppButton, AppCard },
  setup() {
    const update_required = ref(false);
    const last_compilation = ref();
    const last_version = ref();
    const current_compilation = ref();
    const current_version = ref();

    const getLastVersion = async () => {
      const { data } = await axios.get("front_service/");
      last_compilation.value = data.compilation;
      last_version.value = data.version;
      console.log(last_compilation.value);
      console.log(last_version.value);
    };

    const check_update = async () => {
      console.log("check_update");
      await getLastVersion();
      current_compilation.value = localStorage.getItem("current_compilation");
      current_version.value = localStorage.getItem("current_version");
      if (
        last_compilation.value == current_compilation.value &&
        last_version.value == current_version.value
      ) {
        update_required.value = false;
      } else {
        update_required.value = true;
      }
    };

    onMounted(async () => {
      if (
        localStorage.getItem("current_compilation") == undefined ||
        localStorage.getItem("current_version") == undefined
      ) {
        await getLastVersion();
        console.log("Defining build and version");
        localStorage.setItem("current_compilation", last_compilation.value);
        localStorage.setItem("current_version", last_version.value);
        update_required.value = true;
      } else {
        await check_update();
      }
    });

    // onUpdated(async () => {
    //   await check_update();
    // });

    const updateApp = () => {
      console.log("updateApp...");
      // Actualizar el localStorage
      localStorage.setItem("current_compilation", last_compilation.value);
      localStorage.setItem("current_version", last_version.value);
      update_required.value = false;
      // Recargar contenido
      window.location.reload();
    };

    return { update_required, updateApp, check_update };
  },
};
</script>
<style scoped>
.card-update {
  background-color: #06b6d4 !important;
  color: white;
}
</style>
