<template>
  <textarea
    class="input"
    :name="name"
    :rows="rows"
    :required="required"
  ></textarea>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    rows: {
      type: Number,
      default: 4,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
