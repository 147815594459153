<template>
  <div class="picker">
    <Datepicker
      placeholder="Select Month"
      minimum-view="month"
      maximum-view="year"
      v-model="dateinput"
      :disabled-dates="disabledDates"
      format="MM/yy"
    >
      <template v-slot:belowDate>
        <span></span>
      </template>
    </Datepicker>
  </div>
  <input type="hidden" :value="dateinput" :name="name" />
</template>

<script>
import { ref } from "@vue/reactivity";
import Datepicker from "vuejs3-datepicker";

export default {
  components: { Datepicker },
  props: {
    name: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const dateinput = ref(new Date());
    const disabledDates = {
      to: new Date(),
    };

    const d = new Date();
    const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const today = `${d.getFullYear()}-${month}`;

    return { dateinput, today, disabledDates };
  },
};
</script>

<style lang="scss" scoped>
.picker ::v-deep {
  .vuejs3-datepicker {
    @apply w-full;

    &__calendar {
      @apply fixed;
      @apply bottom-0;
      @apply left-0;
      @apply w-full;
    }

    &__value {
      @apply mb-3;
      @apply w-full;
      @apply rounded-md;
      @apply border-gray-300;
      @apply shadow-sm;
      @apply px-3;
      @apply py-2;
      @apply text-bluegray-700;
      @apply text-sm;
      @apply transition-all;
      @apply outline-none;
      @apply min-w-full;
      &:focus:invalid {
        @apply border-red-300;
        @apply shadow-red-300-md;
      }
      &:focus {
        @apply border-gignetblue-300;
        @apply ring;
        @apply ring-gignetblue-200;
        @apply ring-opacity-50;
        @apply ring-offset-2;
      }

      &.disabled {
        @apply bg-bluegray-50;
        @apply text-bluegray-400;
      }
    }
  }
}

.picker::v-deep {
  @screen md {
    .vuejs3-datepicker__calendar {
      @apply absolute;
      @apply w-auto;
      @apply left-auto;
    }
  }
}
</style>
